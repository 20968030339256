import React, { FC } from "react";

import { RightFloatMenusWrapper } from "@spesill/components/organisms";

import { CorrectionType } from "@spesill/hooks/aiRequest/useEvaluateRequest";
import { Document } from "@spesill/models";

import {
  MenuComponentList,
  MenuList,
  MenuNameListType,
} from "./RightFloatSidebar";

type Props = {
  setCurrentMenu: (menu: MenuNameListType) => void;
  setRequestHeadingTitles: (headingTitles: string[]) => void;
  currentMenu: MenuNameListType;
  headingTitles: string[];
  document: Document;
  className?: string;
  selectedHeadingTitles: string[];
  pushSelectedHeadingTitle: (headingTitle: string) => void;
  findAndRemoveSelectedHeadingTitle: (func: (item: string) => boolean) => void;
  review?: string;
  suggestions?: string[];
  corrections?: CorrectionType[];
  requestEvaluate?: (databaseId: string, proofreaderPrompt?: string) => void;
  isEvaluating?: boolean;
  onReflectCorrection?: (correction: CorrectionType) => void;
  onCancelCorrection?: (correction: CorrectionType) => void;
  onFocusCorrection?: (correction: CorrectionType) => void;
  onUpdateCorrection?: (correction: CorrectionType) => void;
  resetEvaluation?: () => void;
};

export const RightFloatMenu: FC<Props> = ({
  setCurrentMenu,
  document,
  currentMenu,
  className = "",
  review,
  suggestions,
  corrections,
  requestEvaluate,
  isEvaluating,
  onReflectCorrection,
  onCancelCorrection,
  onFocusCorrection,
  onUpdateCorrection,
  resetEvaluation,
}) => {
  return (
    <>
      <RightFloatMenusWrapper
        onClose={() => setCurrentMenu("")}
        menus={MenuList.map((menu) => ({
          text: menu,
          onClick: () => setCurrentMenu(menu),
        }))}
        currentMenuText={currentMenu}
        className={className}
      >
        {MenuComponentList[currentMenu]?.component({
          setCurrentMenu,
          document,
          review,
          suggestions,
          corrections,
          requestEvaluate,
          isEvaluating,
          onReflectCorrection,
          onCancelCorrection,
          onFocusCorrection,
          onUpdateCorrection,
          resetEvaluation,
        })}
      </RightFloatMenusWrapper>
    </>
  );
};
