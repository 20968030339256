import * as GC from "@grapecity/spread-sheets";
import { useState } from "react";

import { CorrectResponseType } from "@spesill/models/api/evaluate_spec";

import {
  useEvaluateRequestBase,
  CorrectionType,
} from "./useEvaluateRequestBase";

export const useEvaluateRequestExcel = (
  tenantId: string,
  documentPath: string,
  isReady: boolean,
) => {
  const base = useEvaluateRequestBase(tenantId, documentPath);
  const [sheetCorrections, setSheetCorrections] = useState<CorrectionType[]>(
    [],
  );

  function createCorrectionsFromResponse(
    correctResponses: CorrectResponseType[],
  ): CorrectionType[] {
    return correctResponses.map((correctResponse, index) => {
      const suggestionMarkdown = correctResponse.suggestion;
      const originText = correctResponse.originalText;
      const replacingText = correctResponse.suggestion
        .replace(/~~[^~]+~~/g, "")
        .replace(/\*\*/g, "");
      const comment = correctResponse.comment;
      const reference = correctResponse.originalText;
      return {
        suggestionMarkdown,
        originText,
        replacingText,
        reference,
        comment,
        index,
      };
    });
  }

  const handleCorrections = (correctResponses: CorrectResponseType[]) => {
    const corrections = createCorrectionsFromResponse(correctResponses);
    base.setCorrections(corrections);
    return corrections;
  };

  const requestEvaluateExcel = async (
    databaseId: string,
    proofreaderPrompt?: string,
    documentKind?: string,
  ) => {
    const res = await base.requestEvaluate(
      databaseId,
      proofreaderPrompt,
      documentKind,
    );
    if (res) {
      const corrections = handleCorrections(res.corrections);
      return { ...res, corrections };
    }
    return res;
  };

  const onReflectCorrection = (
    correction: CorrectionType,
    sheet?: GC.Spread.Sheets.Worksheet,
  ) => {
    if (!sheet) return;
    if (correction.location?.type !== "excel") return;
    if (correction.location?.col == null || correction.location?.row == null)
      return;
    const style = correction.location.style as GC.Spread.Sheets.Style;
    sheet
      .getCell(correction.location?.row, correction.location?.col)
      .setStyle(style);
    sheet
      .getCell(correction.location?.row, correction.location?.col)
      .value(correction.replacingText);
    setSheetCorrections(
      sheetCorrections.filter((c) => c.originText !== correction.originText),
    );
  };

  const onCancelCorrection = (
    correction: CorrectionType,
    sheet?: GC.Spread.Sheets.Worksheet,
  ) => {
    if (!isReady) return;
    if (!sheet) return;
    if (correction.location?.type !== "excel") return;
    if (correction.location?.col == null || correction.location?.row == null)
      return;
    const style = correction.location.style as GC.Spread.Sheets.Style;
    sheet
      .getCell(correction.location?.row, correction.location?.col)
      .value(correction.originText);
    sheet
      .getCell(correction.location?.row, correction.location?.col)
      .setStyle(style);
    //該当のコレクションを削除
    setSheetCorrections(
      sheetCorrections.filter((c) => c.originText !== correction.originText),
    );
  };

  return {
    ...base,
    sheetCorrections,
    setSheetCorrections,
    requestEvaluate: requestEvaluateExcel,
    onReflectCorrection,
    onCancelCorrection,
  };
};
