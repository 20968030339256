import { CorrectResponseType } from "@spesill/models/api/evaluate_spec";

import {
  useEvaluateRequestBase,
  CorrectionType,
} from "./useEvaluateRequestBase";

export const useEvaluateRequestPdf = (
  tenantId: string,
  documentPath: string,
) => {
  const base = useEvaluateRequestBase(tenantId, documentPath);

  function createCorrectionsFromResponse(
    correctResponses: CorrectResponseType[],
  ): CorrectionType[] {
    return correctResponses.map((correctResponse, index) => {
      const suggestionMarkdown = correctResponse.suggestion;
      const originText = correctResponse.originalText;
      const replacingText = correctResponse.suggestion
        .replace(/~~[^~]+~~/g, "")
        .replace(/\*\*/g, "");
      const comment = correctResponse.comment;
      const reference = correctResponse.originalText;
      return {
        suggestionMarkdown,
        originText,
        replacingText,
        reference,
        comment,
        index,
      };
    });
  }

  const handleCorrections = (correctResponses: CorrectResponseType[]) => {
    const corrections = createCorrectionsFromResponse(correctResponses);
    base.setCorrections(corrections);
    return corrections;
  };

  const requestEvaluatePdf = async (
    databaseId: string,
    proofreaderPrompt?: string,
    documentKind?: string,
  ) => {
    const res = await base.requestEvaluate(
      databaseId,
      proofreaderPrompt,
      documentKind,
    );
    if (res) {
      const corrections = handleCorrections(res.corrections);
      return { ...res, corrections };
    }
  };

  return {
    ...base,
    requestEvaluate: requestEvaluatePdf,
  };
};
