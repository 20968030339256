import { Editor, NodeViewProps } from "@tiptap/react";
import { useCallback } from "react";

import { CorrectResponseType } from "@spesill/models/api/evaluate_spec";

import {
  useEvaluateRequestBase,
  CorrectionType,
} from "./useEvaluateRequestBase";

export const useEvaluateRequestWord = (
  editor: Editor | null,
  tenantId: string,
  documentPath: string,
) => {
  const base = useEvaluateRequestBase(tenantId, documentPath);

  function createCorrectionsFromResponse(
    correctResponses: CorrectResponseType[],
  ): CorrectionType[] {
    return correctResponses.map((correctResponse, index) => {
      const suggestionMarkdown = correctResponse.suggestion;
      const originText = correctResponse.originalText;
      const replacingText = correctResponse.suggestion
        .replace(/~~[^~]+~~/g, "")
        .replace(/\*\*/g, "");
      const comment = correctResponse.comment;
      const reference = correctResponse.originalText;
      return {
        suggestionMarkdown,
        originText,
        replacingText,
        reference,
        comment,
        index,
      };
    });
  }

  const findProofReaders = useCallback(() => {
    if (!editor) return [];
    const proofReaders: { node: NodeViewProps["node"]; position: number }[] =
      [];
    editor.state.doc.descendants((node, pos) => {
      if (node.type.name === "proofReader") {
        proofReaders.push({ node, position: pos });
      }
    });
    return proofReaders;
  }, [editor]);

  const replaceText = (
    text: string,
    reference: string,
    replacingText: string,
  ) => {
    return text.replace(new RegExp(reference, "gi"), replacingText);
  };

  const onReflectCorrection = (correction: CorrectionType) => {
    if (!editor) return;
    const proofReaders = findProofReaders();
    const proofReader = proofReaders.find(
      (pr) => pr.node.attrs.markdownText === correction.suggestionMarkdown,
    );

    if (!proofReader) return;
    const from = proofReader.position;
    const to = from + proofReader.node.nodeSize;
    const newText = replaceText(
      proofReader.node.attrs.text,
      correction.reference,
      correction.replacingText,
    );

    editor
      .chain()
      .focus()
      .deleteRange({ from, to })
      .insertContentAt({ from, to: from }, newText)
      .run();
    base.setCorrections(
      base.corrections.filter(
        (c) => c.suggestionMarkdown !== correction.suggestionMarkdown,
      ),
    );
    base.removeCorrection(
      (item: CorrectionType) => item.reference === correction.reference,
    );
  };

  const onCancelCorrection = (correction: CorrectionType) => {
    if (!editor) return;
    const proofReaders = findProofReaders();
    const proofReader = proofReaders.find(
      (pr) => pr.node.attrs.markdownText === correction.suggestionMarkdown,
    );

    if (!proofReader) return;
    const from = proofReader.position;
    const to = from + proofReader.node.nodeSize;

    editor
      .chain()
      .focus()
      .deleteRange({ from, to })
      .insertContentAt({ from, to: from }, proofReader.node.attrs.text)
      .run();
    base.removeCorrection(
      (item: CorrectionType) => item.reference === correction.reference,
    );
  };

  type ChangeType = {
    from: number;
    to: number;
    correction: CorrectionType;
  };

  function collectTextCorrections(correctionMapper: CorrectionType[]) {
    const changes: ChangeType[] = [];
    let correctionIndex = 0;
    editor?.state.doc.descendants((node, pos) => {
      if (node.isText) {
        const nodeText = node.text;
        correctionMapper.forEach((correction) => {
          if (
            nodeText &&
            (nodeText.includes(correction.originText) ||
              nodeText === correction.originText)
          ) {
            const from = pos + nodeText.indexOf(correction.originText);
            const to = from + correction.originText.length;
            changes.push({
              from,
              to,
              correction: { ...correction, index: correctionIndex++ },
            });
          }
        });
      }
    });
    // 位置ずれを防ぐため、降順で処理
    changes.sort((a, b) => b.from - a.from);
    return changes;
  }

  function applyTextCorrections(
    changes: ChangeType[],
    results: CorrectionType[],
    correctionMapper: CorrectionType[],
  ) {
    changes.forEach(({ from, to, correction }) => {
      const text = correction.originText;
      const result = editor
        ?.chain()
        .deleteRange({ from, to })
        .insertContentAt(
          { from, to: from },
          {
            type: "proofReader",
            attrs: {
              text,
              markdownText: correction.suggestionMarkdown,
              replacingText: correction.replacingText,
              correction: correctionMapper,
              reference: correction.reference,
              removeCorrection: base.removeCorrection,
              index: correction.index,
            },
          },
        )
        .run();

      if (result) {
        results.push(correction);
      }
    });
  }

  const convertToProofReaderExtension = (
    correctResponses: CorrectResponseType[],
  ) => {
    if (!editor) return;
    const correctionMapper = createCorrectionsFromResponse(correctResponses);
    const results: CorrectionType[] = [];
    const changes = collectTextCorrections(correctionMapper);
    applyTextCorrections(changes, results, correctionMapper);
    // 補正情報は降順になっているので逆順にして設定
    base.setCorrections(results.reverse());
  };

  // Word用の requestEvaluate は、APIリクエスト後に convertToProofReaderExtension を呼ぶ
  const requestEvaluateWord = async (
    databaseId: string,
    proofreaderPrompt?: string,
    documentKind?: string,
  ) => {
    const res = await base.requestEvaluate(
      databaseId,
      proofreaderPrompt,
      documentKind,
    );
    if (res) {
      convertToProofReaderExtension(res.corrections);
    }
    return res;
  };

  return {
    ...base,
    requestEvaluate: requestEvaluateWord,
    convertToProofReaderExtension,
    onReflectCorrection,
    onCancelCorrection,
  };
};
