import { isLocalhost, spesillAIBaseURL } from "@spesill/config/env";
import { ImageResponse, ModelResponse } from "@spesill/models/api/ai_chat";

import { getServerIdToken } from "./apiClient";

// このインターフェースはエンドポイントによって異なるので、エンドポイントごとにインターフェースを定義する
interface ChatApiPayload {
  user_id: string;
  chat_room_id: string;
  tenant_id: string;
  group_id: string;
  question: string;
  with_image: boolean;
  with_movie: boolean;
  grounding: boolean;
  media_contents_path: string | undefined;
}

interface SendAudioPath {
  audio_path: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isImageResponse(response: any): response is ImageResponse {
  return (
    response &&
    "response_id" in response &&
    "width" in response &&
    "height" in response &&
    "image_base64" in response
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isModelResponse(response: any): response is ModelResponse {
  return response && "response_id" in response && "model_url" in response;
}

type SSEEventCallback = (event: MessageEvent) => void;

class SSEClient {
  private baseURL: string;

  constructor(baseURL?: string) {
    this.baseURL = baseURL || spesillAIBaseURL();
  }

  private async getAuthHeaders(endpoint: string): Promise<Headers> {
    const token = isLocalhost()
      ? undefined
      : await getServerIdToken(`${this.baseURL}/${endpoint}`);
    return new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
  }

  async sendMessage(
    endpoint: string,
    payload: ChatApiPayload,
  ): Promise<Response> {
    const headers = await this.getAuthHeaders(endpoint);
    return fetch(`${this.baseURL}/${endpoint}`, {
      method: "POST",
      headers,
      body: JSON.stringify(payload),
    });
  }

  async streamResponse(
    endpoint: string,
    payload: ChatApiPayload,
    onMessage: SSEEventCallback,
    onError?: (error: Error | unknown) => void,
  ): Promise<void> {
    try {
      const response = await this.sendMessage(endpoint, payload);
      if (!response.body) {
        throw new Error("レスポンスボディが読み取れません");
      }

      const contentType = response.headers.get("Content-Type");

      // 画像レスポンスの場合
      if (contentType === "application/json") {
        const jsonResponse = await response.json();

        if (isImageResponse(jsonResponse)) {
          onMessage(
            new MessageEvent("message", { data: JSON.stringify(jsonResponse) }),
          );
          return;
        }

        if (isModelResponse(jsonResponse)) {
          onMessage(
            new MessageEvent("message", { data: JSON.stringify(jsonResponse) }),
          );
          return;
        }
      }

      // ストリームレスポンスの処理
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let done = false;
      while (!done) {
        const { done: doneFlag, value } = await reader.read();
        done = doneFlag;
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        onMessage(new MessageEvent("message", { data: chunk }));
      }
    } catch (error) {
      if (onError) {
        onError(error);
      } else {
        console.error("SSE streaming error:", error);
      }
    }
  }

  async sendAudioPath(endpoint: string, req: SendAudioPath): Promise<Response> {
    const headers = await this.getAuthHeaders(endpoint);
    return fetch(`${this.baseURL}/${endpoint}`, {
      method: "POST",
      headers,
      body: JSON.stringify(req),
    });
  }
  async sttResponse(
    endpoint: string,
    req: SendAudioPath,
    onMessage: SSEEventCallback,
    onError?: (error: Error | unknown) => void,
  ): Promise<void> {
    try {
      const response = await this.sendAudioPath(endpoint, req);
      if (!response.body) {
        throw new Error("レスポンスボディが読み取れません");
      }
      const jsonResponse = await response.json();
      onMessage(
        new MessageEvent("message", { data: JSON.stringify(jsonResponse) }),
      );
      return;
    } catch (error) {
      if (onError) {
        onError(error);
      } else {
        console.error("SSE error:", error);
      }
    }
  }
}

export const sseClient = new SSEClient();
