import React, { FC } from "react";
import {
  AiOutlineClose,
  AiOutlinePlus,
  AiOutlineFile,
  AiOutlineQuestionCircle,
  AiOutlineSetting,
  AiOutlineBell,
  AiOutlineDown,
  AiOutlineFileText,
  AiOutlineReload,
  AiOutlineSearch,
  AiOutlineRight,
  AiOutlineLeft,
  AiOutlineDoubleRight,
  AiOutlineDoubleLeft,
  AiOutlineCheckCircle,
  AiOutlineTable,
} from "react-icons/ai";
import { BiEditAlt, BiListCheck, BiPalette } from "react-icons/bi";
import {
  BsFilterLeft,
  BsDatabase,
  BsListCheck,
  BsTable,
  BsThreeDots,
  BsTrash,
  BsPersonPlus,
  BsPerson,
  BsArrowDown,
  BsArrowUp,
  BsArrowLeft,
  BsArrowRight,
  BsDownload,
  BsQuestionCircle,
  BsFillMicFill,
} from "react-icons/bs";
import { FaVideo, FaFileAlt } from "react-icons/fa";
import {
  FaRegFileLines,
  FaRegCopy,
  FaTable,
  FaRegCircle,
  FaListCheck,
  FaList,
  FaListOl,
  FaImage,
  FaGlobe,
} from "react-icons/fa6";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { FiLink2 } from "react-icons/fi";
import { GrFormFilter, GrDocumentDownload } from "react-icons/gr";
import {
  HiOutlineEyeOff,
  HiOutlineEye,
  HiOutlineChatAlt,
} from "react-icons/hi";
import { IoMdSend, IoIosColorWand } from "react-icons/io";
import { IoDocumentOutline, IoClose } from "react-icons/io5";
import {
  LuLayoutDashboard,
  LuHeading1,
  LuHeading2,
  LuHeading3,
  LuBold,
  LuUnderline,
  LuStrikethrough,
  LuItalic,
  LuUndo2,
  LuRedo2,
} from "react-icons/lu";
import {
  MdOutlineSchool,
  MdDone,
  MdContentCopy,
  MdContentCut,
  MdContentPaste,
  MdOutlineComment,
  MdOutlineThumbUp,
  MdOutlineThumbDown,
  MdFilterListAlt,
  MdSpellcheck,
} from "react-icons/md";
import {
  PiArrowsInLineHorizontalFill,
  PiArrowsHorizontalBold,
  PiSpinnerGapLight,
} from "react-icons/pi";
import {
  RiFileExcel2Fill,
  RiFileWord2Fill,
  RiOrganizationChart,
  RiPencilLine,
} from "react-icons/ri";
import { RxReload } from "react-icons/rx";
import {
  TbMailForward,
  TbPencilPlus,
  TbListSearch,
  TbFileUpload,
  TbFileDownload,
  TbPdf,
  TbLayoutSidebarRightCollapse,
  TbLayoutSidebarLeftExpand,
  TbLayoutSidebarLeftCollapse,
} from "react-icons/tb";
import { VscLinkExternal } from "react-icons/vsc";

export const ICONS = [
  "aiOutlineFileText",
  "aiOutlinePlus",
  "aiOutlineClose",
  "aiOutlineFile",
  "aiOutlineQuestionCircle",
  "aiOutlineSetting",
  "aiOutlineBell",
  "aiOutlineDown",
  "aiOutlineReload",
  "aiOutlineSearch",
  "aiOutlineRight",
  "aiOutlineLeft",
  "aiOutlineDoubleRight",
  "aiOutlineDoubleLeft",
  "aiOutlineCheckCircle",
  "aiOutlineTable",
  "biEditAlt",
  "biPalette",
  "biListCheck",
  "bsFilterLeft",
  "bsDatabase",
  "bsListCheck",
  "bsTable",
  "bsThreeDots",
  "bsTrash",
  "bsArrowDown",
  "bsArrowUp",
  "bsArrowRight",
  "bsArrowLeft",
  "bsDownload",
  "bsPersonPlus",
  "bsPerson",
  "bsQuestionCircle",
  "bsFillMicFill",
  "faRegFileLines",
  "faRegCopy",
  "faListCheck",
  "faListOl",
  "faList",
  "faTable",
  "faImage",
  "faVideo",
  "faFileAlt",
  "faWandMagicSparkles",
  "faGlobe",
  "tbPdf",
  "fiLink2",
  "grFormFilter",
  "grDocumentDownload",
  "hiOutlineEye",
  "hiOutlineEyeOff",
  "hiOutlineChatAlt",
  "mdOutlineSchool",
  "luLayoutDashboard",
  "luHeading1",
  "luHeading2",
  "luHeading3",
  "luBold",
  "luUnderline",
  "luStrikethrough",
  "luItalic",
  "luUndo2",
  "luRedo2",
  "mdDone",
  "mdContentCopy",
  "mdContentCut",
  "mdContentPaste",
  "mdOutlineComment",
  "mdOutlineThumbUp",
  "mdOutlineThumbDown",
  "mdSpellcheck",
  "mdFilterListAlt",
  "riFileExcel2Fill",
  "riFileWord2Fill",
  "riPencilLine",
  "riOrganizationChart",
  "tbMailForward",
  "tbPencilPlus",
  "tbListSearch",
  "tbFileUpload",
  "tbFileDownload",
  "tbLayoutSidebarRightCollapse",
  "tbLayoutSidebarLeftExpand",
  "tbLayoutSidebarLeftCollapse",
  "ioMdSend",
  "rxReload",
  "ioDocumentOutline",
  "IoIosColorWand",
  "ioClose",
  "VscLinkExternal",
  "faRegCircle",
  "piArrowsInLineHorizontalFill",
  "piArrowsHorizontalBold",
  "piSpinnerGapLight",
] as const satisfies readonly string[];

export type IconType = (typeof ICONS)[number];
export type IconColorType = `text-${string}-${number | string}` | "text-white";
export type IconSizeType = `${number}rem`;
export type IconPropsType = {
  icon: IconType;
  className?: string;
  size?: IconSizeType;
  color?: IconColorType;
};

export const Icon: FC<IconPropsType> = ({
  icon,
  size = "2rem",
  className = "",
  color = "text-blueGray-400",
}: IconPropsType) => {
  const iconProps = {
    className: `${className} ${color}`,
    size: size,
  };

  const icons = {
    aiOutlinePlus: <AiOutlinePlus {...iconProps} />,
    aiOutlineClose: <AiOutlineClose {...iconProps} />,
    aiOutlineFile: <AiOutlineFile {...iconProps} />,
    aiOutlineQuestionCircle: <AiOutlineQuestionCircle {...iconProps} />,
    aiOutlineSetting: <AiOutlineSetting {...iconProps} />,
    aiOutlineBell: <AiOutlineBell {...iconProps} />,
    aiOutlineDown: <AiOutlineDown {...iconProps} />,
    aiOutlineFileText: <AiOutlineFileText {...iconProps} />,
    aiOutlineReload: <AiOutlineReload {...iconProps} />,
    aiOutlineSearch: <AiOutlineSearch {...iconProps} />,
    aiOutlineRight: <AiOutlineRight {...iconProps} />,
    aiOutlineLeft: <AiOutlineLeft {...iconProps} />,
    aiOutlineCheckCircle: <AiOutlineCheckCircle {...iconProps} />,
    aiOutlineTable: <AiOutlineTable {...iconProps} />,
    aiOutlineDoubleRight: <AiOutlineDoubleRight {...iconProps} />,
    aiOutlineDoubleLeft: <AiOutlineDoubleLeft {...iconProps} />,
    biEditAlt: <BiEditAlt {...iconProps} />,
    biPalette: <BiPalette {...iconProps} />,
    biListCheck: <BiListCheck {...iconProps} />,
    bsFilterLeft: <BsFilterLeft {...iconProps} />,
    bsDatabase: <BsDatabase {...iconProps} />,
    bsListCheck: <BsListCheck {...iconProps} />,
    bsTable: <BsTable {...iconProps} />,
    bsThreeDots: <BsThreeDots {...iconProps} />,
    bsTrash: <BsTrash {...iconProps} />,
    bsPersonPlus: <BsPersonPlus {...iconProps} />,
    bsPerson: <BsPerson {...iconProps} />,
    bsArrowDown: <BsArrowDown {...iconProps} />,
    bsArrowUp: <BsArrowUp {...iconProps} />,
    bsArrowRight: <BsArrowRight {...iconProps} />,
    bsArrowLeft: <BsArrowLeft {...iconProps} />,
    bsDownload: <BsDownload {...iconProps} />,
    bsQuestionCircle: <BsQuestionCircle {...iconProps} />,
    bsFillMicFill: <BsFillMicFill {...iconProps} />,
    faRegFileLines: <FaRegFileLines {...iconProps} />,
    faRegCopy: <FaRegCopy {...iconProps} />,
    faTable: <FaTable {...iconProps} />,
    faRegCircle: <FaRegCircle {...iconProps} />,
    faListCheck: <FaListCheck {...iconProps} />,
    faListOl: <FaListOl {...iconProps} />,
    faList: <FaList {...iconProps} />,
    faImage: <FaImage {...iconProps} />,
    faVideo: <FaVideo {...iconProps} />,
    faFileAlt: <FaFileAlt {...iconProps} />,
    faWandMagicSparkles: <FaWandMagicSparkles {...iconProps} />,
    faGlobe: <FaGlobe {...iconProps} />,
    tbPdf: <TbPdf {...iconProps} />,
    fiLink2: <FiLink2 {...iconProps} />,
    grFormFilter: <GrFormFilter {...iconProps} />,
    grDocumentDownload: <GrDocumentDownload {...iconProps} />,
    hiOutlineEye: <HiOutlineEye {...iconProps} />,
    hiOutlineEyeOff: <HiOutlineEyeOff {...iconProps} />,
    hiOutlineChatAlt: <HiOutlineChatAlt {...iconProps} />,
    luLayoutDashboard: <LuLayoutDashboard {...iconProps} />,
    luHeading1: <LuHeading1 {...iconProps} />,
    luHeading2: <LuHeading2 {...iconProps} />,
    luHeading3: <LuHeading3 {...iconProps} />,
    luBold: <LuBold {...iconProps} />,
    luUnderline: <LuUnderline {...iconProps} />,
    luStrikethrough: <LuStrikethrough {...iconProps} />,
    luItalic: <LuItalic {...iconProps} />,
    luUndo2: <LuUndo2 {...iconProps} />,
    luRedo2: <LuRedo2 {...iconProps} />,
    mdOutlineSchool: <MdOutlineSchool {...iconProps} />,
    mdDone: <MdDone {...iconProps} />,
    mdContentCopy: <MdContentCopy {...iconProps} />,
    mdContentCut: <MdContentCut {...iconProps} />,
    mdContentPaste: <MdContentPaste {...iconProps} />,
    mdOutlineComment: <MdOutlineComment {...iconProps} />,
    mdOutlineThumbUp: <MdOutlineThumbUp {...iconProps} />,
    mdOutlineThumbDown: <MdOutlineThumbDown {...iconProps} />,
    mdFilterListAlt: <MdFilterListAlt {...iconProps} />,
    mdSpellcheck: <MdSpellcheck {...iconProps} />,
    riFileExcel2Fill: <RiFileExcel2Fill {...iconProps} />,
    riFileWord2Fill: <RiFileWord2Fill {...iconProps} />,
    riOrganizationChart: <RiOrganizationChart {...iconProps} />,
    riPencilLine: <RiPencilLine {...iconProps} />,
    tbMailForward: <TbMailForward {...iconProps} />,
    tbPencilPlus: <TbPencilPlus {...iconProps} />,
    tbListSearch: <TbListSearch {...iconProps} />,
    tbFileUpload: <TbFileUpload {...iconProps} />,
    tbFileDownload: <TbFileDownload {...iconProps} />,
    tbLayoutSidebarRightCollapse: (
      <TbLayoutSidebarRightCollapse {...iconProps} />
    ),
    tbLayoutSidebarLeftExpand: <TbLayoutSidebarLeftExpand {...iconProps} />,
    tbLayoutSidebarLeftCollapse: <TbLayoutSidebarLeftCollapse {...iconProps} />,
    ioMdSend: <IoMdSend {...iconProps} />,
    rxReload: <RxReload {...iconProps} />,
    ioDocumentOutline: <IoDocumentOutline {...iconProps} />,
    IoIosColorWand: <IoIosColorWand {...iconProps} />,
    ioClose: <IoClose {...iconProps} />,
    VscLinkExternal: <VscLinkExternal {...iconProps} />,
    piArrowsInLineHorizontalFill: (
      <PiArrowsInLineHorizontalFill {...iconProps} />
    ),
    piArrowsHorizontalBold: <PiArrowsHorizontalBold {...iconProps} />,
    piSpinnerGapLight: <PiSpinnerGapLight {...iconProps} />,
  };

  return icons[icon];
};
